import { useCallback } from 'react'

type Filters = {
  keyword?: string
  audiences?: string[] | null
  practiceAreas?: string[] | null
  languages?: string[] | null
  deployments?: string[] | null
  regionsServed?: string[] | null
  topic?: string[] | null
  hqs?: string[] | null
  attributes?: string[] | null
}

// Helper function should be defined before usage
const sortAndJoin = (value?: string[] | null): string => {
  if (Array.isArray(value)) {
    return value.map(String).sort().join(',')
  }
  return value ?? ''
}

const sortString = (value?: string): string => {
  if (typeof value === 'string' && value.includes(',')) {
    return value
      .split(',')
      .map(Number)
      .sort((a, b) => a - b)
      .join(',')
  }
  return value ?? ''
}

const useSearchQuery = () => {
  const getSearchQuery = useCallback((filters: Filters, newProductType?: string): string => {
    if (
      filters?.keyword ||
      filters?.audiences ||
      filters?.practiceAreas ||
      filters?.languages ||
      filters?.deployments ||
      filters?.regionsServed ||
      filters?.topic ||
      filters?.hqs ||
      filters?.attributes ||
      newProductType
    ) {
      return [
        `keyword=${filters.keyword ?? ''}`,
        `audiences=${sortAndJoin(filters.audiences)}`,
        `practiceAreas=${sortAndJoin(filters.practiceAreas)}`,
        `languages=${sortAndJoin(filters.languages)}`,
        `deployments=${sortAndJoin(filters.deployments)}`,
        `regionsServed=${sortAndJoin(filters.regionsServed)}`,
        `topic=${sortAndJoin(filters.topic)}`,
        `subTopic=${sortString(newProductType)}`,
        `hqs=${sortAndJoin(filters.hqs)}`,
        `attributes=${sortAndJoin(filters.attributes)}`,
      ].join('&')
    }
    return ''
  }, [])

  return { getSearchQuery }
}

export default useSearchQuery
