import Tooltip from '@mui/material/Tooltip'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import detectIncognito from 'detectincognitojs'
import getBrowserFingerprint from 'get-browser-fingerprint'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { TailSpin } from 'react-loading-icons'
import useSearchQuery from 'src/components/CustomHooks/SearchQuery'
import { useIncreaseSolutionCount } from 'src/components/CustomHooks/UseIncreaseSolutionCount'
import { PremiumSearchBoxTabs } from 'src/components/Tabs/PremiumSearchBoxTabs'
import * as enVariables from 'src/config/config'
import { HOMEPAGE_REMOVE_ITEMS, SHOW_ASK_AI } from 'src/config/config'
import { apolloSdk } from 'src/graphql/apolloSdk'
import { IEnum_Content_Contenttype, useAutosuggestLazyQuery } from 'src/graphql/generated/hooks'

import { LoadingAnimationHr } from '../LoadingAnimationHr'
import { SmartSearchBox } from '../SmartSearchBox/SmartSearchBox'
import { ThemeOnly } from '../ThemeOnly'
import { CompanyAutosuggestItem } from './Dropdown/CompanyAutosuggestItem'
import { PremiumAutosuggestItem } from './Dropdown/PremiumAutosuggestItem'

export interface PremiumSearchBoxProps {
  className?: string
}

export interface SmartSearchSources {
  description?: string | null
  logo?: string | null
  sourceUrl?: string | null
  title?: string | null
  contentType?: IEnum_Content_Contenttype | null
}

export const PremiumSearchBox: React.FC<PremiumSearchBoxProps> = ({ className = '', ...props }) => {
  const [searchText, setSearchText] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [searchFocused, setSearchFocused] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const router = useRouter()
  const { increaseSolutionCount } = useIncreaseSolutionCount()
  const { getSearchQuery } = useSearchQuery()

  const [autosuggest, { data }] = useAutosuggestLazyQuery()

  // show Ask AI / Keyword Search
  const [isExpand, setIsExpand] = useState(false)
  const [aiSmartSearchId, setAiSmartSearchId] = useState<string>('')
  // let logId

  const onFocus = () => {
    setSearchFocused(true)
  }
  const onLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => {
        setSearchFocused(false)
      }, 500)
    }
  }
  const onTextChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value ? e.target.value : ''
    setSearchText(text)
    const fingerprint = await getBrowserFingerprint()
    const browser = await detectIncognito()

    const customAccessQuery: string = await getSearchQuery(
      {
        keyword: text,
        audiences: null,
        practiceAreas: null,
        languages: null,
        deployments: null,
        regionsServed: null,
        hqs: null,
        attributes: null,
      },
      '',
    )
    if (enVariables.SHOW_FREE_SEARCH == true && fingerprint && fingerprint > 0 && customAccessQuery != '') {
      autosuggest({
        variables: {
          keyword: text,
          accessFreeSearch: true,
          uniqueAddress: !browser?.isPrivate && browser?.browserName !== '' ? fingerprint.toString() : '0',
          customAccessQuery: customAccessQuery,
        },
        fetchPolicy: 'no-cache',
      })
    } else {
      autosuggest({ variables: { keyword: text } })
    }
    setShowPopup(text.length > 0)

    if (text.length > 0) {
      if (enVariables.ENVIRONMENT === 'production') {
        mixpanel.track('search_content', {
          keyword: text,
        })
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (enVariables.SHOW_FREE_SEARCH) {
        increaseSolutionCount({
          keyword: searchText,
          audiences: null,
          practiceAreas: null,
          languages: null,
          deployments: null,
          regionsServed: null,
          hqs: null,
          attributes: null,
        })
      }
      router.push({ pathname: '/search', query: { keyword: searchText } })
    }
  }

  const increaseSolutionCountAsync = () => {
    if (enVariables.SHOW_FREE_SEARCH) {
      increaseSolutionCount({
        keyword: searchText,
        audiences: null,
        practiceAreas: null,
        languages: null,
        deployments: null,
        regionsServed: null,
        hqs: null,
        attributes: null,
      })
    }
  }

  const {
    categories,
    solutions,
    events,
    contents,
    companies,
    isFreeSearch = false,
  } = data?.autosuggest || {
    solutions: [],
    categories: [],
    events: [],
    contents: [],
    companies: [],
  }
  const onTabSelect = (idx: number) => {
    setTabIndex(idx)
  }
  const hasAutosuggest = events.length > 0 || categories.length > 0 || solutions.length > 0 || contents.length > 0

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState('')
  const [sources, setSources] = useState<SmartSearchSources[]>([])
  const [errrorMessage, setErrrorMessage] = useState('')
  const [identicalId, setIdenticalId] = useState<number | null>(null)

  useEffect(() => {
    setTableData('')
    setSources([])
  }, [isExpand])

  // call api for AI search
  const fetchdata = async (inputValue: string = '') => {
    setErrrorMessage('')
    setLoading(true)
    try {
      setTableData('')
      setSources([])
      const { aiSmartSearch } = await apolloSdk.aiSmartSearch({ keyword: inputValue })
      if (aiSmartSearch?.data?.id) {
        setAiSmartSearchId(aiSmartSearch?.data?.id)
      }
      if (aiSmartSearch?.data?.identicalId) {
        setIdenticalId(aiSmartSearch?.data?.identicalId)
      }
      // logId = aiSmartSearch.data.id

      if (aiSmartSearch && aiSmartSearch.success && aiSmartSearch.data) {
        setTableData(aiSmartSearch.data.result)
        if (aiSmartSearch.data.sources) {
          setSources(aiSmartSearch.data.sources)
        }
      } else {
        setTableData('')
        setSources([])
        setErrrorMessage('Sorry - I wasn’t able to find an answer. Please try a different question.')
      }

      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } catch (error) {
      setTableData('')
      setSources([])
      setErrrorMessage('Sorry - I wasn’t able to find an answer. Please try a different question.')
      setLoading(false)
    }
  }

  return (
    <>
      {!isExpand ? (
        <div className={cn('relative z-9', className)} onFocus={onFocus} onBlur={onLostFocus}>
          <div
            className={cn(
              'z-20 relative rounded-[1.875rem] border-2 bg-white border-solid flex flex-row items-center pl-[1.3125rem] pr-[0.75rem] h-[3.875rem]',
              searchFocused || showPopup ? 'border-turquoise shadow-md' : 'border-white',
            )}
            {...props}
          >
            <IonIcon name="search" className="mr-2 w-[0.9225rem] h-[0.9225rem]" />
            <input
              autoFocus
              type="text"
              className="focus:outline-none grow font-karla font-medium text-textdark  text-[14px] mr-[14px] leading-[42px] search-text"
              placeholder="Search by keyword, product name, etc."
              onChange={onTextChanged}
              onKeyDown={handleKeyDown}
            />
            <Link href={{ pathname: '/search', query: { keyword: searchText } }}>
              {searchText === '' ? (
                <Tooltip title="Search all results" placement="right">
                  <div className="rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center">
                    <IonIcon className="mr-0 !w-[20px] !h-[20px]" name="arrow-forward-sharp" />
                  </div>
                </Tooltip>
              ) : (
                <div className="rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center">
                  <IonIcon className="mr-0 !w-[20px] !h-[20px]" name="arrow-forward-sharp" />
                </div>
              )}
            </Link>
          </div>
          <div
            className={cn(
              'absolute z-10 top-0 rounded-[1.875rem] bg-white shadow-md w-full pt-[4.2rem] pb-2 overflow-hidden flex flex-col',
              {
                hidden: !showPopup || !hasAutosuggest || !searchFocused,
              },
            )}
            tabIndex={0}
          >
            <div className="w-full flex-1 flex flex-col items-center">
              <div className="w-full">
                <div className="w-full px-5 pt-1">
                  <PremiumSearchBoxTabs
                    className="w-1/10 mx-auto text-[1rem] text-black"
                    tabs={['ALL', 'CONTENT', 'DIRECTORY', 'TOPICS', 'COMPANIES', 'EVENTS']}
                    counts={[0, 0, 0, 0, 0]}
                    showNumber={false}
                    onTabSelect={onTabSelect}
                  />
                </div>
                <div className="w-full bg-navy opacity-20 h-[1px]" />
              </div>
              <div className="w-full">
                <div className="overflow-y-auto max-h-[35rem] w-full pt-2">
                  {(tabIndex === 0 || tabIndex === 1) && (
                    <>
                      {tabIndex === 0 && contents.length > 0 && (
                        <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                          Contents
                        </p>
                      )}
                      <div className="w-full">
                        {contents.map((data, idx) => (
                          <PremiumAutosuggestItem data={data} search={searchText} key={idx} />
                        ))}
                        {contents.length === 0 && tabIndex === 1 && (
                          <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                            No content found matching keyword {searchText}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {(tabIndex === 0 || tabIndex === 2) && (
                    <>
                      {tabIndex === 0 && solutions.length > 0 && (
                        <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                          Solutions
                        </p>
                      )}
                      <div className="w-full">
                        {solutions.map((data, idx) => (
                          <PremiumAutosuggestItem
                            data={data}
                            search={searchText}
                            key={idx}
                            increaseSolutionCountAsync={increaseSolutionCountAsync}
                            showAsText={!isFreeSearch && isFreeSearch != null ? true : false}
                          />
                        ))}
                        {solutions.length === 0 && tabIndex === 2 && (
                          <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                            No solution found matching keyword {searchText}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {(tabIndex === 0 || tabIndex === 3) && (
                    <>
                      {tabIndex === 0 && categories.length > 0 && (
                        <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                          Categories
                        </p>
                      )}
                      <div className="w-full">
                        {categories.map((data, idx) => (
                          <PremiumAutosuggestItem data={data} search={searchText} key={idx} icon={false} />
                        ))}
                        {categories.length === 0 && tabIndex === 3 && (
                          <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                            No category found matching keyword {searchText}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {(tabIndex === 0 || tabIndex === 4) && (
                    <>
                      {tabIndex === 0 && companies.length > 0 && (
                        <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                          Companies
                        </p>
                      )}
                      <div className="w-full">
                        {companies.map((data, idx) => (
                          <CompanyAutosuggestItem data={data} search={searchText} key={idx} isPremiumSearch />
                        ))}
                        {companies.length === 0 && tabIndex === 4 && (
                          <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                            No companies found matching keyword {searchText}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {(tabIndex === 0 || tabIndex === 5) && (
                    <>
                      {tabIndex === 0 && events.length > 0 && (
                        <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                          Events
                        </p>
                      )}
                      <div className="w-full">
                        {events.map((data, idx) => (
                          <PremiumAutosuggestItem data={data} search={searchText} key={idx} />
                        ))}
                        {events.length === 0 && tabIndex === 5 && (
                          <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                            No event found matching keyword {searchText}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SmartSearchBox fetchdata={fetchdata} className="transition-[all .3s .4s] md:mt-[2.75rem] mt-[1.25rem] mb-1" />
      )}

      {SHOW_ASK_AI && SHOW_ASK_AI != undefined ? (
        <>
          <ThemeOnly theme="legaltech">
            <div className={cn('flex flex-row items-center justify-center mt-2')}>
              <div className={cn('flex flex-col w-full')}>
                <div className={cn('rounded-full flex flex-row items-center justify-center w-auto mb-2 text-white')}>
                  <Link href={{ pathname: '/' }} passHref className={cn('center')}>
                    <div
                      className={cn(
                        'rounded-fulltext-white flex flex-none cursor-pointer items-center justify-center w-auto mb-2 text-white underline underline-offset-[0.1rem] decoration-white decoration-solid decoration-[.1rem]',
                      )}
                      onClick={() => setIsExpand(!isExpand)}
                    >
                      {!HOMEPAGE_REMOVE_ITEMS ? (
                        !isExpand ? (
                          'Ask AI'
                        ) : (
                          'Keyword Search'
                        )
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 19L7.5 13.5L2 11L7.5 8.5L10 3L12.5 8.5L18 11L12.5 13.5L10 19ZM18 21L16.75 18.25L14 17L16.75 15.75L18 13L19.25 15.75L22 17L19.25 18.25L18 21Z"
                              fill="#29ef97"
                            />
                          </svg>
                          <span className="font-primary font-regular text-[14px] font-[500] leading-[16.37px] text-[#FFFFFF] ml-[4px]">
                            {!isExpand ? 'Ask AI' : 'Keyword Search'}
                          </span>
                        </>
                      )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </ThemeOnly>

          <ThemeOnly theme="legaltech">
            <div className="search-result">
              {loading ? (
                <>
                  <div className="flex justify-center mb-10">
                    <div className="flex items-center">
                      <TailSpin stroke="#FFF" height="2em" /> <div className="pl-3 text-white"> Loading...</div>
                    </div>
                  </div>
                  <div className="flex justify-center mb-0">
                    {loading && (
                      <LoadingAnimationHr
                        className="flex flex-row space-x-4"
                        result={''}
                        sources={[]}
                      ></LoadingAnimationHr>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {isExpand && tableData && (
                    <LoadingAnimationHr
                      className={`flex flex-row space-x-4 ${sources.length > 3 ? 'premium-search-result-scroll' : ''}`}
                      result={tableData}
                      sources={sources}
                      id={aiSmartSearchId}
                      identicalId={identicalId}
                    ></LoadingAnimationHr>
                  )}
                </>
              )}
              {!loading && isExpand && errrorMessage && (
                <div className="alert alert-warning text-center p-3 mb-2 text-white" role="alert">
                  {errrorMessage}
                </div>
              )}
            </div>
          </ThemeOnly>
        </>
      ) : (
        ''
      )}
    </>
  )
}
