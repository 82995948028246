import cn from 'classnames'
import Link from 'next/link'
import React, { useState } from 'react'
import styles from 'src/containers/Home/Home.module.scss'

import { IconButton } from '../../components/global/IconButton/IconButton'
import { NewsletterModal } from '../Newsletter/NewsletterModal'
import { InsightCards } from './InsightCards'

//import { TrendingTopicCard, TrendingTopicCardProps } from 'src/components/TrendingTopicCard/TrendingTopicCard'
// import { IPremiumAudienceItemFragment, useTrendingContentsQuery } from 'src/graphql/generated/hooks'
// import { useAuthenticated } from 'src/hooks/useAuthenticated'

interface InsightsProps {
  className?: string
  trendingContents: any[]
}

export const Insights: React.FC<InsightsProps> = ({ trendingContents }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [openTooltipCardId, setOpenTooltipCardId] = useState<string | null>(null)

  function closeModal() {
    setModalOpen(false)
  }

  return (
    <div className={'flex flex-row justify-center bg-white'}>
      <div className="!max-w-[1080px] w-full flex flex-row items-center py-8">
        <div className="mx-auto">
          {/*     <!-- Section Header --> */}
          <div className={cn(styles.homeBuyPremiumBadge)}></div>
          <div className="text-left">
            <h2 className="pt-[12px] pb-[4px] text-[24px] leading-[35px] font-bold font-secondary text-[#222222]">
              Insights
            </h2>
            <p className="font-primary regular text-[18px] text-[#767676] ">
              Stay informed with the latest market analyses and articles on trending topics.
            </p>
            {/* <button className="mt-4 px-6 py-2 bg-blue-700 text-white rounded-lg shadow hover:bg-blue-800">
              Subscribe to Insights
            </button> */}

            <div className="text-gray-600 mt-[22px] home_subscribe_insights">
              <Link
                onClick={(e) => {
                  e.preventDefault() // Prevent the navigation
                  e.stopPropagation() // Stop event propagation
                  setModalOpen(true)
                }}
                className="inline-block focus:outline-none"
                href={'https://hub.legaltechnologyhub.com/subscribe'}
              >
                <IconButton
                  type="primary"
                  label="Subscribe to Insights"
                  className="insights_btn font-secondary w-[auto] h-[3.125rem]"
                />
              </Link>
            </div>
            <NewsletterModal isOpen={isModalOpen} onClose={closeModal} />
          </div>

          {/* <!-- Insights Grid --> */}
          <div className="mt-[32px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[30px]">
            {/* <!-- Card --> */}
            {trendingContents?.map((item: any, index: number) => (
              <InsightCards
                key={index}
                insightItem={item}
                openTooltipCardId={openTooltipCardId}
                setOpenTooltipCardId={setOpenTooltipCardId}
              />
            ))}
          </div>

          {/* <!-- View All Button --> */}
          <div className="mt-10 text-center">
            <Link
              href="/content-insights/?audiences=1,2&contentType=KnowHow"
              className="view-all hover:underline text-lg font-medium"
            >
              View all
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
