import cn from 'classnames'
import { decode } from 'html-entities'
import { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SITE_URL } from 'src/config/config'
import {
  IAskAILogRatingInput,
  IEnum_Content_Contenttype,
  useUpdateAskAILogRatingMutation,
} from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'
import { showToast } from 'src/store/actions/toastAction'

import { PremiumContentIcon } from './PremiumContentIcon/PremiumContentIcon'

function decodeHtmlEntities(text: string): string {
  const decodedText = decode(text)
  return decodedText
}

interface RatingButtonProps {
  rating: number
  onRatingChange: (newRating: number) => void
}
const RatingButton: React.FC<RatingButtonProps> = ({ rating, onRatingChange }) => {
  const maxRating = 5
  // Tooltip text for each rating
  const tooltips = ['Poor', 'Fair', 'Average', 'Good', 'Excellent']
  return (
    <div className="flex items-center gap-2">
      {' '}
      {Array.from({ length: maxRating }).map((_, index) => {
        const buttonRating = index + 1
        return (
          <div key={buttonRating} className="relative group">
            {/* Tooltip */}
            <div
              className="absolute bottom-14 left-1/2 transform -translate-x-1/2 scale-0
                         rounded bg-black text-white text-xs py-1 px-2 shadow-md
                         group-hover:scale-100 transition duration-300 ease-in-out" // Reduced tooltip text size
            >
              {`${tooltips[index]}`}
            </div>
            {/* Button */}
            <button
              className={`w-8 h-8 text-sm border-2 rounded transition-colors // Reduced button size
                ${
                  rating && buttonRating <= rating
                    ? 'bg-blue-700 text-white border-white' // Selected state
                    : 'bg-blue-800 text-white border-blue-500 hover:bg-blue-600 hover:text-white' // Default and hover state
                }`}
              onClick={() => onRatingChange(buttonRating)}
            >
              {buttonRating}
            </button>
          </div>
        )
      })}
    </div>
  )
}
export interface SmartSearchSources {
  description?: string | null
  logo?: string | null
  sourceUrl?: string | null
  title?: string | null
  contentType?: IEnum_Content_Contenttype | null
}
interface LoadingAnimationHrProps {
  className?: string
  result: string
  sources: SmartSearchSources[]
  id?: string
  identicalId?: number | null
}

interface CardComponentProps {
  title?: string
  description?: string
  imgUrl?: string
  linkTitle?: string
  linkUrl?: string
  type?: string
  shimRed: boolean
}
const CardComponent: React.FC<CardComponentProps> = ({
  title = '',
  description = '',
  imgUrl = '',
  linkTitle = '',
  linkUrl = '#',
  type = '',
  shimRed = true,
}) => {
  const imgSrc = imgUrl ? imgUrl : ''

  //myString.substring(4, 10)
  const [showProgress, setShowProgress] = useState(shimRed)
  const [descriptionText, setDescriptionText] = useState('')
  const [titleText, setTitleText] = useState('')
  let classProgess = showProgress ? 'shim-red' : ''
  let bg = showProgress ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'

  useEffect(() => {
    const descText = description.replace(/(<([^>]+)>)/gi, '')
    const subString = descText.substring(0, 95)

    const titleDesc = linkTitle.replace(/(<([^>]+)>)/gi, '')
    const titleD = titleDesc.substring(0, 29)
    if (descText.length > subString.length) {
      setDescriptionText(subString + '...')
    } else {
      setDescriptionText(subString)
    }
    if (titleDesc.length > titleD.length) {
      setTitleText(titleD + '...')
    } else {
      setTitleText(titleD)
    }

    setShowProgress(shimRed)

    classProgess = showProgress ? 'shim-red' : ''
    bg = showProgress ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'
  }, [shimRed])
  const getContentType = () => {
    switch (type) {
      case IEnum_Content_Contenttype.LandscapeArticle:
        return IEnum_Content_Contenttype.LandscapeArticle
      case IEnum_Content_Contenttype.EvaluationFramework:
        return IEnum_Content_Contenttype.EvaluationFramework
      case IEnum_Content_Contenttype.RequirementsTemplate:
        return IEnum_Content_Contenttype.RequirementsTemplate
      case IEnum_Content_Contenttype.BusinessCase:
        return IEnum_Content_Contenttype.BusinessCase
      case IEnum_Content_Contenttype.CompetitiveAnalysis:
        return IEnum_Content_Contenttype.CompetitiveAnalysis
      case IEnum_Content_Contenttype.PilotGuide:
        return IEnum_Content_Contenttype.PilotGuide
      case IEnum_Content_Contenttype.ThoughtLeadership:
        return IEnum_Content_Contenttype.ThoughtLeadership
      case IEnum_Content_Contenttype.Blog:
        return IEnum_Content_Contenttype.Blog
      case IEnum_Content_Contenttype.VendorResource:
        return IEnum_Content_Contenttype.VendorResource
      case IEnum_Content_Contenttype.IndustryAnalysis:
        return IEnum_Content_Contenttype.IndustryAnalysis
      case IEnum_Content_Contenttype.BuyersGuide:
        return IEnum_Content_Contenttype.BuyersGuide
      default:
        return ''
    }
  }

  const contentType = getContentType()

  const shouldShowDefaultImage =
    contentType != ''
      ? contentType &&
        [
          IEnum_Content_Contenttype.LandscapeArticle,
          IEnum_Content_Contenttype.EvaluationFramework,
          IEnum_Content_Contenttype.RequirementsTemplate,
          IEnum_Content_Contenttype.BusinessCase,
          IEnum_Content_Contenttype.CompetitiveAnalysis,
          IEnum_Content_Contenttype.PilotGuide,
          IEnum_Content_Contenttype.ThoughtLeadership,
          IEnum_Content_Contenttype.Blog,
          IEnum_Content_Contenttype.VendorResource,
          IEnum_Content_Contenttype.IndustryAnalysis,
          IEnum_Content_Contenttype.BuyersGuide,
        ].includes(contentType)
      : false
  const logoContent = (
    <>
      {imgUrl != '' ? (
        <Image
          className="bg-white rounded-[.5rem] drop-shadow-md flex items-center justify-center w-[35px] h-[25px] min-w-[35px] min-h-[25px]"
          src={imgUrl}
          alt={title}
          width={20}
          height={20}
          priority
        />
      ) : shouldShowDefaultImage && contentType ? (
        <PremiumContentIcon contentType={contentType} size="sm" defaultImage={imgUrl} />
      ) : (
        // <Image
        //   className="bg-white rounded-[.5rem] drop-shadow-md flex items-center justify-center w-[20px] h-[20px] min-w-[20px] min-h-[20px]"
        //   src={logo}
        //   alt={title}
        //   width={20}
        //   height={20}
        //   priority
        // />
        <span className="relative w-1/4 h-[24px] bg-white rounded-[1rem] mr-1">
          <span className={'w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess}></span>
        </span>
      )}
    </>
  )

  return (
    <div key={title} className={bg + '  items-center rounded-lg w-64 overflow-hidden mb-4 ml-2 min-w-250'}>
      <Link
        href={linkUrl}
        passHref
        className="inline-flex items-center justify-center text-base"
        rel="noopener noreferrer"
        title={linkTitle}
        target="_blank"
      >
        <div className="p-2 grid justify-center">
          {/* <h3 className="relative w-[220px] h-[28px] bg-white rounded-[1rem] pl-2 font-bold mb-2 text-sm leading-6">
            <div className={'w-full absolute top-0 h-[28px] rounded-[1rem] truncate ' + classProgess}>{title}</div>
          </h3> */}
          <p className="w-[232px] h-[28px] flex flex-row text-xs leading-6">
            {imgSrc && (
              <Link className="text-left mt+2" href={imgSrc}>
                {logoContent}
              </Link>
            )}
            {!imgSrc && logoContent}
            <span
              className={
                'relative w-full h-[24px]  truncate pl-2  text-blue-500 rounded-lg bg-gray-50 hover:text-glue-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white hover:underline '
              }
            >
              <span
                className={
                  'text-ellipsis overflow-hidden ... w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess
                }
              >
                {titleText}
              </span>
            </span>
          </p>
          <p className="relative w-[232px] h-[80px] bg-white mb-2 text-xs leading-6">
            <span className={' w-[232px] absolute top-0 h-[80px]  ' + classProgess}>{descriptionText}</span>
          </p>
          {/* <p className="w-[226px] h-[28px] flex flex-row text-xs leading-6">
            {imgSrc && <img src={imgSrc} alt="Card Image" className="w-[24px] h-[24px] mr-1 object-fit" />}
            {!imgSrc && (
              <span className="relative w-1/4 h-[24px] bg-white rounded-[1rem] mr-1">
                <span className={'w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess}></span>
              </span>
            )}
            <Link
              href={linkUrl} passHref
              className="inline-flex items-center justify-center text-base text-blue-500 rounded-lg bg-gray-50 hover:text-glue-900 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white hover:underline relative w-full h-[24px] bg-white rounded-[1rem] truncate pl-2"
              rel="noopener noreferrer"
            >
              <span className={'w-full absolute top-0 h-[24px] rounded-[1rem] ' + classProgess}>{linkTitle}</span>
            </Link>
          </p> */}
          {/* <div className="relative w-full sm:w-1/2 bg-gray-200 rounded">
            <div className="w-full absolute top-0 h-4 rounded shim-red"></div>
          </div> */}
        </div>
      </Link>
    </div>
  )
}

export const LoadingAnimationHr: NextPage<LoadingAnimationHrProps> = ({
  className,
  result,
  sources,
  id,
  identicalId,
}) => {
  const [resultHeight, setResultHeight] = useState('h-[100px]') //result ? 'hfull' : 'h-[100px]'
  const [classProgess, setClassProgess] = useState('shim-red')
  const [showRating, setShowRating] = useState(result ? true : false)
  const [updateRating] = useUpdateAskAILogRatingMutation()
  const { session } = useAuthenticated()
  const userId = session?.user.id
  const dispatch: Dispatch<any> = useDispatch()
  let bg = !result ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'

  useEffect(() => {
    setClassProgess('shim-red')
    setResultHeight('h-[100px]')
    bg = !result ? 'bg-slate-400 bg-sky-500/50' : 'bg-white bg-white-500/50'
    if (result) {
      setClassProgess('')
      setResultHeight('h-full')
      setShowRating(true)
    }
  }, [result])
  const formattedText = result
    .replace(/\n/g, '<br>')
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/### (.*?)\n/g, '<h3>$1</h3>')
    .replace(/## (.*?)\n/g, '<h2>$1</h2>')
    .replace(/# (.*?)\n/g, '<h1>$1</h1>')
    .replace(/\*(.*?)\*/g, '<em>$1</em>')
    .replace(/###/g, '')
    .replace(/#/g, '')
  const handleRatingChange = async (newRating: number) => {
    try {
      const req: IAskAILogRatingInput = {
        rating: newRating,
        id,
        userID: userId,
        identicalId: identicalId || null,
      }

      const res = await updateRating({ variables: { data: req } })
      const success = res?.data?.updateAskAILogRating?.success
      const message = res?.data?.updateAskAILogRating?.message || 'Rating updated successfully'

      setShowRating(!success)
      dispatch(showToast(message, success ? 'success' : 'error'))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating rating:', error)
    }
  }
  return (
    <div className={cn('flex flex-col items-center')}>
      <div className="flex flex-row space-x-4 mb-4">
        <div className={bg + ' p-4 flex flex-row items-center rounded-lg overflow-hidden'}>
          <p
            className={'w-[784px] bg-white rounded-[1rem] p-2 mb-2 ' + resultHeight + ' ' + classProgess}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </div>
      </div>

      <div className={cn(className, 'justify-between mb-4')}>
        {sources.length > 0 ? (
          <>
            {sources.map((item: any) => (
              <CardComponent
                title={item?.title}
                linkUrl={item?.sourceUrl}
                linkTitle={item?.title}
                description={decodeHtmlEntities(item?.description || '')}
                imgUrl={item?.logo || `${SITE_URL}/vendor-logo.svg`}
                type={item?.contentType}
                shimRed={item ? false : true}
              />
            ))}
          </>
        ) : result && sources.length == 0 ? (
          ''
        ) : (
          <>
            <CardComponent shimRed={true} />
            <CardComponent shimRed={true} />
            <CardComponent shimRed={true} />
          </>
        )}
      </div>
      {showRating && (
        <div className="mb-4 flex items-center space-x-4">
          <h3 className="text-xl font-semibold text-white">Rate this answer:</h3>
          <RatingButton rating={0} onRatingChange={handleRatingChange} />
        </div>
      )}
    </div>
  )
}
