import detectIncognito from 'detectincognitojs'
import getBrowserFingerprint from 'get-browser-fingerprint'
import { useEffect, useState } from 'react'
import { SHOW_FREE_SEARCH } from 'src/config/config'
import { useSearchSolutionsLazyQuery } from 'src/graphql/generated/hooks'

import useSearchQuery from './SearchQuery'

type Filters = {
  keyword?: string
  audiences?: string[] | null
  practiceAreas?: string[] | null
  languages?: string[] | null
  deployments?: string[] | null
  regionsServed?: string[] | null
  topic?: string[] | null
  hqs?: string[] | null
  attributes?: string[] | null
}

export const useIncreaseSolutionCount = () => {
  const [loadSolutions, { loading, data, error }] = useSearchSolutionsLazyQuery()
  const [fingerprint, setFingerprint] = useState(0)
  const [browser, setBrowser] = useState({ browserName: '', isPrivate: false })
  const { getSearchQuery } = useSearchQuery()
  const getfingerprintID = async (cancelExcution = false) => {
    if (cancelExcution) {
      return
    } else {
      const fingerprintID = await getBrowserFingerprint()
      setFingerprint(fingerprintID)
    }
  }

  useEffect(() => {
    getfingerprintID(!SHOW_FREE_SEARCH)
  }, [])

  useEffect(() => {
    detectIncognito().then((result: any) => {
      setBrowser(result)
    })
  }, [])

  const increaseSolutionCount = async (filters: Filters = {}, newProductType?: string) => {
    const baseQuery = {
      keyword: filters.keyword ?? null,
      audiences: filters.audiences ?? null,
      practiceAreas: filters.practiceAreas ?? null,
      languages: filters.languages ?? null,
      deployments: filters.deployments ?? null,
      attributes: filters.attributes ?? null,
      regionsServed: filters.regionsServed ?? null,
      subTopic: newProductType ?? null,
      hqs: filters.hqs ?? null,
    }

    const customAccessQuery: string = getSearchQuery(filters, newProductType)
    const finalQuery =
      SHOW_FREE_SEARCH && fingerprint && fingerprint > 0 && customAccessQuery
        ? {
            ...baseQuery,
            accessFreeSearch: true,
            uniqueAddress: !browser?.isPrivate && browser?.browserName !== '' ? fingerprint.toString() : '0',
            customAccessQuery,
          }
        : baseQuery

    await loadSolutions({ variables: { query: finalQuery }, fetchPolicy: 'no-cache' })
  }

  return { increaseSolutionCount, loading, data, error }
}
